<template>
	<div class="QualityReportPublishList">
		<list :searchUrl="searchUrl" :searchParams.sync="searchParams" :headers="headers">
			<template #searchSlot>
				<v-input label="计划名称" v-model="searchParams.planName" />
				<v-select label="计划类型" v-model="searchParams.planType" :options="planTypeOps" />
				<v-select label="计划层级" v-model="searchParams.scopeType" :options="scopeTypeOps" />
				<v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
				<v-datepicker label="报告发布时间" type="rangedatetimer" :startTime.sync="searchParams.reportReleaseTimeS" :endTime.sync="searchParams.reportReleaseTimeE" />
			</template>
			<template #operateSlot="scope">
				<v-button text="查看" type="text" permission="detail"  @click="detail(scope.row)" />
				<v-button text="上传报告" type="text" @click="upload(scope.row)" permission="upload" v-show='!isShowDownload(scope.row)'/>
				<v-button text="下载报告" type="text" @click="download(scope.row)" permission="download" v-show='isShowDownload(scope.row)'/>
			</template>
		</list>
		<el-dialog
			:width="'40%'"
			title="上传报告"
			:visible.sync="dialogVisible"
		>
			<form-panel
				ref="formPanel"
				v-bind="submitConfig"
				:form="form"
				:submitUseParams="true"
				:hasHeader="false"
				:backAfterSave="false"
			>

				<el-form-item label="报告" :rules="[{ required: true, message: '请上传报告', trigger: 'change' }]" prop="url">
					<v-uploader2
						:action="uploadURL"
						filePath="customerFiles"
						v-model="uploadParams"
						:acceptFileType="supportFileType"
						tip="只能上传excel、ppt、pdf、word文件，且文件不超过100M"
						:limit="1"
						:fileSize="100"
						:beforeRemove="beforeRemove"
						@input="uploadInput"
					/>
				</el-form-item>
			</form-panel>
		</el-dialog>
	</div>
</template>

<script>
import { downloadHelper } from '@/common/utils.js'
import { vUploader2 } from "components/control";
import { communityParams } from 'common/select2Params'
import {
	getListURL,
	uploadURL,
	uploadReportURL
} from './api'
import {
	planTypeMap,
	planTypeOps,
	scopeTypeMap,
	scopeTypeOps
} from './map'

export default {
	name: 'QualityReportPublishList',
	components: {
		vUploader2
	},
	computed: {
		scopeTypeOps () {
			const userType = +this.$store.state.userInfo.userType
			let list = []
			if ([106, 100].includes(userType)) {
				list = scopeTypeOps()
			}else if ([102].includes(userType)) {
				list = scopeTypeOps().slice(1)
			}else if ([101].includes(userType)) {
				list = scopeTypeOps().slice(2)
			}
			list.unshift({
				text: '全部',
				value: undefined,
				label: '全部'
			})
			return list
		},
	},
	data() {
		return {
			searchUrl: getListURL,
			planTypeOps: planTypeOps(1),
			// scopeTypeOps: scopeTypeOps(1),
			searchParams: {
				planName: '',
				planType: undefined,
				scopeType: undefined,
				communityId: undefined,
				reportReleaseTimeS: '',
				reportReleaseTimeE: '',
				reportProgressType: 2,
				reportProgress: 4,
			},
			headers: [
				{
					prop: 'planType',
					label: '计划类型',
					formatter (row) {
						return planTypeMap[row.planType]
					}
				},
				{
					prop: 'taskName',
					label: '计划名称'
				},
				{
					prop: 'templateName',
					label: '检查表模板'
				},
				{
					prop: 'communityName',
					label: '检查对象'
				},
				{
					prop: 'reportReleaseTime',
					label: '报告发布时间'
				},
				{
					prop: 'scopeType',
					label: '计划层级',
					formatter (row) {
						return scopeTypeMap[row.scopeType]
					}
				},
				{
					prop: 'responsibleUser',
					label: '计划责任人'
				},
				{
					prop: '',
					label: '检查得分/总分',
					formatter (row) {
						const { executeUserId, checkScore = 0,  totalScore = 0, displayModel, checkMark = 0 } = row
						if (!executeUserId) { // 任务执行状态为未开始时，返回空
							return ''
						}
						// displayModel 1 按评分 2 按是否合格
						if (displayModel === 1) {
							return `${checkMark} / ${totalScore}`
						}else{
							return `${checkScore}项合格 / ${totalScore}项`
						}
					}
				},
			],
			dialogVisible: false,
			submitConfig: {
				submitUrl: uploadReportURL
			},
			form: {
				url: [],
				taskId: undefined
			},
			uploadURL,
			communityParams,
			supportFileType: ".ppt,.docx,.doc,.pdf,.excel",
			uploadParams: []
		}
	},
	methods: {
		isShowDownload (row) {
			return !!row.reportUrl
		},
		detail(row) {
			console.log('row', row)
			this.$router.push({
				name: 'qualityReportUnpublishForm',
				query: {
					id: row.taskId,
					isDetail: true,
          isRectify: row.rectificationProgress,
				}
			})
		},
		upload (row) {
			this.uploadParams = []
			this.form.taskId = row.taskId
			this.dialogVisible = true
		},
		download (row) {
			const { reportUrl, planName, planType } = row
			const fileName = `${ planName }${ planType }`
			downloadHelper.downloadBySrc({
				src: reportUrl,
				fileName
			})
		},
		async beforeRemove() {
			// 上传文件删除前
			return await this.$confirm("此操作将永久删除该文件, 是否继续？");
		},
		uploadInput(response, file, playTime) {
			if (file) {
				this.form.url = response[0]
			}else{
				this.form.url = ''
			}
			// if(file){
			// 	this.form.coursewareSize = (file.file.size/1024/1024).toFixed(2)
			// 	this.form.coursewareType = file.file.name.substring(file.file.name.lastIndexOf(".")+1)
			// 	if(this.form.coursewareType == 'docx'||this.form.coursewareType == 'doc'){
			// 	this.form.coursewareType = "word"
			// 	}
			// 	this.form.coursewareTime = playTime
			// } else {
			// 	this.form.coursewareSize = ""
			// 	this.form.coursewareType = ""
			// }

			console.log(response,this.form.coursewareType,this.form.coursewareSize,playTime, "9999999999999");
		},
	}
}
</script>
